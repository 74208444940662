@media (max-width: 2000px) {
    .Contenttitle {
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 50px;
    }

    .custom-card {
        width: 150px;
        height: 230px;
        border-radius: 15px;
    }

    .footer {
        text-align: center;
        color: #ffffff;
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        font-family: 'Th Sarabun New', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .MainContentContainer {
        padding: 24px;
        margin: 0;
        min-height: 280px;
        background-color: #f1f1f1;
        border-radius: 15px;
    }

    .DB-Card-container1 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .DB-Card-container2 {
        width: 100%;
        height: 750px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .PieChartContainer {
        width: 850px;
        height: 1000px;
        display: flex;
        flex-direction: column;
        font-size: 25px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .cardTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .setcardContent {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selectContainer {
        border-radius: 15px;
        margin-right: 10px;
        font-size: 30px;
        height: 50px;
    }

    .gridContainer {
        font-size: 25px;
    }

    .cardsectionContent {
        font-size: 50px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
        color: white;
        margin-left: 50px;
    }

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }

    .dataCard {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
        width: 100%;
        height: 100%;
    }

    .pieChartTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .modalContent {
        font-size: 30px;
    }

    .userProfileContainer {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text {
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        color: rgb(0, 0, 0);
    }

    .text strong {
        font-size: 30px;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .commsci-image {
        width: 30%;
    }

    .partner-image {
        width: 80px;
        height: 80px;
    }

    .Modelcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        font-size: 35px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .paperContainer {
        width: 77%;
        padding: 20px;
        margin: 0 auto;
        text-align: center;
    }

    .buttonfilterStyle {
        padding: 25px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: none;
        color: #7BBD8F;
        font-size: 30px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
        margin-right: 10px;
    }

    .buttonprofile {
        padding: 25px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #7BBD8F;
        border: none;
        color: #ffffff;
        font-size: 30px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
        margin-right: 10px;
    }

    .button-card {
        font-size: 25px;
        padding: 30px 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #7BBD8F;
        border: none;
        color: #ffffff;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .flex-card {
        padding: 32px;
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        text-align: left;
    }

    .cardContent {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 100px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardCover {
        height: 300px;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
    }

    .cardCover .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .paperContent {
        padding: 50px;
        margin: 0 auto;
        text-align: center;
    }

    .gridItem {
        margin-bottom: 4%;
        padding: 20px;
    }

    .cardItem-head {
        height: 91%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardItem {
        height: 90%;
        width: 90%;
        margin: auto;
        border-radius: 15px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardIitleTCover {
        padding: 20px;
    }

    .cardItemCover {
        height: 80%;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
    }

    .cardImage {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
}

@media (max-width: 1800px) {
    .line-image {
        width: 400px;
        height: 150px;
    }

    .MainContentContainer {
        padding: 24px;
        margin: 0;
        min-height: 280px;
        background-color: #f1f1f1;
        border-radius: 15px;
    }

    .responsive-container {
        width: 100%;
        height: 400px;
    }

    .DB-Card-container1 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .DB-Card-container2 {
        width: 100%;
        height: 700px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .PieChartContainer {
        width: 850px;
        height: 1000px;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .CardContainer {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .cardTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .setcardContent {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selectContainer {
        margin-right: 10px;
        font-size: 30px;
        height: 50px;
    }

    .gridContainer {
        font-size: 25px;
    }

    .cardsectionContent {
        font-size: 50px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
        color: white;
        margin-left: 50px;
    }

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }

    .dataCard {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
        width: 100%;
        height: 100%;
    }

    .pieChartTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .modalContent {
        font-size: 30px;
    }

    .userProfileContainer {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text {
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        color: rgb(0, 0, 0);
    }

    .text strong {
        font-size: 30px;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .commsci-image {
        width: 50%;
    }

    .partner-image {
        width: 80px;
        height: 80px;
    }

    .Modelcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        font-size: 35px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .paperContainer {
        width: 100%;
        padding: 20px;
        margin: 0 auto;
        text-align: center;
    }

    .buttonfilterStyle {
        padding: 25px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: none;
        color: #7BBD8F;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .button-card {
        font-size: 20px;
        padding: 25px 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #7BBD8F;
        border: none;
        color: #ffffff;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .flex-card {
        padding: 32px;
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        text-align: left;
    }

    .Contenttitle {
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 50px;
    }

    .cardContent {
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardCover {
        height: 300px;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
    }

    .cardCover .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .paperContent {
        padding: 50px;
        margin: 0 auto;
        text-align: center;
    }

    .gridItem {
        margin-bottom: 4%;
        padding: 20px;
    }

    .cardItem-head {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardItem {
        height: 90%;
        width: 90%;
        margin: auto;
        border-radius: 15px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardIitleTCover {
        padding: 20px;
    }

    .cardItemCover {
        height: 80%;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
    }

    .cardImage {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
}

@media (max-width: 1690px) {
    .line-image {
        width: 400px;
        height: 150px;
    }

    .MainContentContainer {
        padding: 24px;
        margin: 0;
        min-height: 280px;
        background-color: #f1f1f1;
        border-radius: 15px;
    }

    .responsive-container {
        width: 100%;
        height: 400px;
    }

    .DB-Card-container1 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .DB-Card-container2 {
        width: 100%;
        height: 800px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .PieChartContainer {
        width: 850px;
        height: 1000px;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .CardContainer {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .cardTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .setcardContent {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selectContainer {
        margin-right: 10px;
        font-size: 30px;
        height: 50px;
    }

    .gridContainer {
        font-size: 25px;
    }

    .cardsectionContent {
        font-size: 45px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
        color: white;
        margin-left: 50px;
    }

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }

    .dataCard {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
        width: 100%;
        height: 100%;
    }

    .pieChartTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .modalContent {
        font-size: 30px;
    }

    .userProfileContainer {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text {
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        color: rgb(0, 0, 0);
    }

    .text strong {
        font-size: 30px;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .commsci-image {
        width: 50%;
    }

    .partner-image {
        width: 80px;
        height: 80px;
    }

    .Modelcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        font-size: 35px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .paperContainer {
        width: 100%;
        padding: 20px;
        margin: 0 auto;
        text-align: center;
    }

    .buttonfilterStyle {
        padding: 25px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: none;
        color: #7BBD8F;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .button-card {
        font-size: 20px;
        padding: 25px 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #7BBD8F;
        border: none;
        color: #ffffff;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .flex-card {
        padding: 32px;
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        text-align: left;
    }

    .Contenttitle {
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 50px;
    }

    .cardContent {
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardCover {
        height: 300px;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
    }

    .cardCover .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .paperContent {
        padding: 50px;
        margin: 0 auto;
        text-align: center;
    }

    .gridItem {
        margin-bottom: 4%;
        padding: 20px;
    }

    .cardItem-head {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardItem {
        height: 90%;
        width: 90%;
        margin: auto;
        border-radius: 15px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardIitleTCover {
        padding: 20px;
    }

    .cardItemCover {
        height: 80%;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
    }

    .cardImage {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
}

@media (max-width: 1200px) {
    .Contenttitle {
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 40px;
    }

    .custom-card {
        width: 200px;
        height: 230px;
        border-radius: 15px;
    }

    .footer {
        text-align: center;
        color: #ffffff;
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        font-family: 'Th Sarabun New', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .MainContentContainer {
        padding: 24px;
        margin: 0;
        min-height: 280px;
        background-color: #f1f1f1;
        border-radius: 15px;
    }

    .DB-Card-container1 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .DB-Card-container2 {
        width: 100%;
        height: 800px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .PieChartContainer {
        width: 850px;
        height: 1000px;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .CardContainer {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .cardTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .setcardContent {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selectContainer {
        margin-right: 10px;
        font-size: 30px;
        height: 50px;
    }

    .gridContainer {
        font-size: 25px;
    }

    .cardsectionContent {
        font-size: 40px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
        color: white;
        margin-left: 50px;
    }

    .cardContent {
        margin: auto;
        border-radius: 15px;
        width: 100%;
        height: 100%;
    }

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }

    .dataCard {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
        width: 100%;
        height: 100%;
    }

    .pieChartTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .modalContent {
        font-size: 30px;
    }

    .userProfileContainer {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text {
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        color: rgb(0, 0, 0);
    }

    .text strong {
        font-size: 30px;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .commsci-image {
        width: 50%;
    }

    .partner-image {
        width: 80px;
        height: 80px;
    }

    .Modelcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        font-size: 35px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .paperContainer {
        width: 100%;
        padding: 20px;
        margin: 0 auto;
        text-align: center;
    }

    .buttonfilterStyle {
        padding: 25px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: none;
        color: #7BBD8F;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .button-card {
        font-size: 20px;
        padding: 25px 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #7BBD8F;
        border: none;
        color: #ffffff;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .flex-card {
        padding: 32px;
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        text-align: left;
    }

    .cardContent {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardCover {
        height: 300px;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
    }

    .cardCover .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .paperContent {
        padding: 10px;
        margin: 0 auto;
        text-align: center;
    }

    .gridItem {
        margin-bottom: 4%;
        padding: 20px;
    }

    .cardItem-head {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardItem {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardIitleTCover {
        padding: 20px;
    }

    .cardItemCover {
        height: 80%;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
    }

    .cardImage {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
}

@media (max-width: 1000px) {
    .Contenttitle {
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 40px;
    }

    .custom-card {
        width: 200px;
        height: 230px;
        border-radius: 15px;
    }

    .footer {
        text-align: center;
        color: #ffffff;
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        font-family: 'Th Sarabun New', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .MainContentContainer {
        padding: 24px;
        margin: 0;
        min-height: 280px;
        background-color: #f1f1f1;
        border-radius: 15px;
    }

    .DB-Card-container1 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .DB-Card-container2 {
        width: 100%;
        height: 800px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .PieChartContainer {
        width: 850px;
        height: 1000px;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .CardContainer {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .cardTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .setcardContent {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selectContainer {
        margin-right: 10px;
        font-size: 30px;
        height: 50px;
    }

    .gridContainer {
        font-size: 25px;
    }

    .cardsectionContent {
        font-size: 40px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
        color: white;
        margin-left: 50px;
    }

    .cardContent {
        margin: auto;
        border-radius: 15px;
        width: 100%;
        height: 100%;
    }

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }

    .dataCard {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
        width: 100%;
        height: 100%;
    }

    .pieChartTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .modalContent {
        font-size: 30px;
    }

    .userProfileContainer {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text {
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        color: rgb(0, 0, 0);
    }

    .text strong {
        font-size: 30px;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .commsci-image {
        width: 50%;
    }

    .partner-image {
        width: 80px;
        height: 80px;
    }

    .Modelcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        font-size: 35px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .paperContainer {
        width: 100%;
        padding: 20px;
        margin: 0 auto;
        text-align: center;
    }

    .buttonfilterStyle {
        padding: 25px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: none;
        color: #7BBD8F;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .button-card {
        font-size: 20px;
        padding: 25px 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #7BBD8F;
        border: none;
        color: #ffffff;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .flex-card {
        padding: 32px;
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        text-align: left;
    }

    .cardContent {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardCover {
        height: 300px;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
    }

    .cardCover .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .paperContent {
        padding: 10px;
        margin: 0 auto;
        text-align: center;
    }

    .gridItem {
        margin-bottom: 4%;
        padding: 20px;
    }

    .cardItem-head {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardItem {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardIitleTCover {
        padding: 20px;
    }

    .cardItemCover {
        height: 80%;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
    }

    .cardImage {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
}
@media (max-width: 900px) {
    .Contenttitle {
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 40px;
    }

    .custom-card {
        width: 200px;
        height: 230px;
        border-radius: 15px;
    }

    .footer {
        text-align: center;
        color: #ffffff;
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        font-family: 'Th Sarabun New', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .MainContentContainer {
        padding: 24px;
        margin: 0;
        min-height: 280px;
        background-color: #f1f1f1;
        border-radius: 15px;
    }

    .DB-Card-container1 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .DB-Card-container2 {
        width: 100%;
        height: 800px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .PieChartContainer {
        width: 850px;
        height: 1000px;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .CardContainer {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .cardTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .setcardContent {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selectContainer {
        margin-right: 10px;
        font-size: 30px;
        height: 50px;
    }

    .gridContainer {
        font-size: 25px;
    }

    .cardsectionContent {
        font-size: 40px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
        color: white;
        margin-left: 50px;
    }

    .cardContent {
        margin: auto;
        border-radius: 15px;
        width: 100%;
        height: 100%;
    }

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }

    .dataCard {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
        width: 100%;
        height: 100%;
    }

    .pieChartTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .modalContent {
        font-size: 30px;
    }

    .userProfileContainer {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text {
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        color: rgb(0, 0, 0);
    }

    .text strong {
        font-size: 30px;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .commsci-image {
        width: 50%;
    }

    .partner-image {
        width: 80px;
        height: 80px;
    }

    .Modelcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        font-size: 35px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .paperContainer {
        width: 100%;
        padding: 20px;
        margin: 0 auto;
        text-align: center;
    }

    .buttonfilterStyle {
        padding: 25px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: none;
        color: #7BBD8F;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .button-card {
        font-size: 20px;
        padding: 25px 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #7BBD8F;
        border: none;
        color: #ffffff;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .flex-card {
        padding: 32px;
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        text-align: left;
    }

    .cardContent {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardCover {
        height: 300px;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
    }

    .cardCover .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .paperContent {
        padding: 10px;
        margin: 0 auto;
        text-align: center;
    }

    .gridItem {
        margin-bottom: 4%;
        padding: 20px;
    }

    .cardItem-head {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardItem {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardIitleTCover {
        padding: 20px;
    }

    .cardItemCover {
        height: 80%;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
    }

    .cardImage {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
}
@media (max-width: 768px) {
    .Contenttitle {
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 30px;
    }

    .custom-card {
        width: 100%;
        height: 230px;
        border-radius: 15px;
    }

    .footer {
        text-align: center;
        color: #ffffff;
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        font-family: 'Th Sarabun New', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .MainContentContainer {
        padding: 24px;
        margin: 0;
        min-height: 280px;
        background-color: #f1f1f1;
        border-radius: 15px;
    }

    .DB-Card-container1 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .DB-Card-container2 {
        width: 100%;
        height: 700px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .PieChartContainer {
        width: 850px;
        height: 1000px;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .CardContainer {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .cardTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .setcardContent {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selectContainer {
        margin-right: 10px;
        font-size: 30px;
        height: 50px;
    }

    .gridContainer {
        font-size: 25px;
    }

    .cardsectionContent {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
        color: white;
        margin-left: 50px;
    }

    .cardContent {
        margin: auto;
        border-radius: 15px;
        width: 100%;
        height: 100%;
    }

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }

    .dataCard {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
        width: 100%;
        height: 100%;
    }

    .pieChartTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .modalContent {
        font-size: 30px;
    }

    .userProfileContainer {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text {
        font-size: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        color: rgb(0, 0, 0);
    }

    .text strong {
        font-size: 20px;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .commsci-image {
        width: 80%;
    }

    .partner-image {
        width: 80px;
        height: 80px;
    }

    .Modelcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        font-size: 35px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .paperContainer {
        width: 100%;
        padding: 10px;
        margin: 0 auto;
        text-align: center;
    }

    .buttonfilterStyle {
        padding: 25px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: none;
        color: #7BBD8F;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .button-card {
        font-size: 20px;
        padding: 25px 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #7BBD8F;
        border: none;
        color: #ffffff;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .flex-card {
        padding: 32px;
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        text-align: left;
    }

    .cardContent {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardCover {
        height: 300px;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
    }

    .cardCover .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .paperContent {
        padding: 50px;
        margin: 0 auto;
        text-align: center;
    }

    .gridItem {
        margin-bottom: 4%;
        padding: 20px;
    }

    .cardItem-head {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardItem {
        height: 90%;
        width: 90%;
        margin: auto;
        border-radius: 15px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardIitleTCover {
        padding: 20px;
    }

    .cardItemCover {
        height: 80%;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
    }

    .cardImage {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
}

@media (max-width: 500px) {
    .Contenttitle {
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 30px;
    }

    .responsive-image {
        max-width: 80%;
        height: auto;
    }

    .footer {
        text-align: center;
        color: #ffffff;
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        font-family: 'Th Sarabun New', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .MainContentContainer {
        padding: 24px;
        margin: 0;
        min-height: 280px;
        background-color: #f1f1f1;
        border-radius: 15px;
    }

    .DB-Card-container1 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .DB-Card-container2 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .PieChartContainer {
        width: 850px;
        height: 1000px;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .CardContainer {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .cardTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .setcardContent {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selectContainer {
        margin-right: 10px;
        font-size: 30px;
        height: 50px;
    }

    .gridContainer {
        font-size: 25px;
    }

    .cardsectionContent {
        font-size: 25px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
        color: white;
        margin-left: 50px;
    }

    .cardContent {
        margin: auto;
        border-radius: 15px;
        width: 100%;
        height: 100%;
    }

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }

    .dataCard {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
        width: 100%;
        height: 100%;
    }

    .pieChartTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .modalContent {
        font-size: 30px;
    }

    .userProfileContainer {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text {
        font-size: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        color: rgb(0, 0, 0);
    }

    .text strong {
        font-size: 20px;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .commsci-image {
        width: 80%;
    }

    .partner-image {
        width: 80px;
        height: 80px;
    }

    .Modelcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        font-size: 35px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .paperContainer {
        width: 100%;
        padding: 10px;
        margin: 0 auto;
        text-align: center;
    }

    .buttonfilterStyle {
        padding: 25px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: none;
        color: #7BBD8F;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .button-card {
        font-size: 20px;
        padding: 25px 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #7BBD8F;
        border: none;
        color: #ffffff;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .flex-card {
        padding: 32px;
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        text-align: left;
    }

    .cardContent {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardCover {
        height: 300px;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
    }

    .cardCover .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .paperContent {
        padding: 1px;
        margin: 0 auto;
        text-align: center;
    }

    .gridItem {
        margin-bottom: 4%;
        padding: 20px;
    }

    .cardItem-head {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardItem {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardIitleTCover {
        padding: 20px;
    }

    .cardItemCover {
        height: 100%;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
    }

    .cardImage {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
}

@media (max-width: 400px) {
    .Contenttitle {
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 30px;
    }

    .responsive-image {
        max-width: 80%;
        height: auto;
    }

    .footer {
        text-align: center;
        color: #ffffff;
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        font-family: 'Th Sarabun New', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .MainContentContainer {
        padding: 24px;
        margin: 0;
        min-height: 280px;
        background-color: #f1f1f1;
        border-radius: 15px;
    }

    .DB-Card-container1 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .DB-Card-container2 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .PieChartContainer {
        width: 850px;
        height: 1000px;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .CardContainer {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .cardTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .setcardContent {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selectContainer {
        margin-right: 10px;
        font-size: 30px;
        height: 50px;
    }

    .gridContainer {
        font-size: 25px;
    }

    .cardsectionContent {
        font-size: 25px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
        color: white;
        margin-left: 50px;
    }

    .cardContent {
        margin: auto;
        border-radius: 15px;
        width: 100%;
        height: 100%;
    }

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }

    .dataCard {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
        width: 100%;
        height: 100%;
    }

    .pieChartTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .modalContent {
        font-size: 30px;
    }

    .userProfileContainer {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text {
        font-size: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        color: rgb(0, 0, 0);
    }

    .text strong {
        font-size: 20px;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .commsci-image {
        width: 80%;
    }

    .partner-image {
        width: 80px;
        height: 80px;
    }

    .Modelcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        font-size: 35px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .paperContainer {
        width: 100%;
        padding: 10px;
        margin: 0 auto;
        text-align: center;
    }

    .buttonfilterStyle {
        padding: 25px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: none;
        color: #7BBD8F;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .button-card {
        font-size: 20px;
        padding: 25px 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #7BBD8F;
        border: none;
        color: #ffffff;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .flex-card {
        padding: 32px;
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        text-align: left;
    }

    .cardContent {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardCover {
        height: 300px;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
    }

    .cardCover .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .paperContent {
        padding: 1px;
        margin: 0 auto;
        text-align: center;
    }

    .gridItem {
        margin-bottom: 4%;
        padding: 20px;
    }

    .cardItem-head {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardItem {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardIitleTCover {
        padding: 20px;
    }

    .cardItemCover {
        height: 100%;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
    }

    .cardImage {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
}

@media (max-width: 300px) {
    .Contenttitle {
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 30px;
    }

    .responsive-image {
        max-width: 80%;
        height: auto;
    }

    .footer {
        text-align: center;
        color: #ffffff;
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        font-family: 'Th Sarabun New', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .MainContentContainer {
        padding: 24px;
        margin: 0;
        min-height: 280px;
        background-color: #f1f1f1;
        border-radius: 15px;
    }

    .DB-Card-container1 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .DB-Card-container2 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .PieChartContainer {
        width: 850px;
        height: 1000px;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .CardContainer {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .cardTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .setcardContent {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selectContainer {
        margin-right: 10px;
        font-size: 30px;
        height: 50px;
    }

    .gridContainer {
        font-size: 25px;
    }

    .cardsectionContent {
        font-size: 25px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
        color: white;
        margin-left: 50px;
    }

    .cardContent {
        margin: auto;
        border-radius: 15px;
        width: 100%;
        height: 100%;
    }

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }

    .dataCard {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
        width: 100%;
        height: 100%;
    }

    .pieChartTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .modalContent {
        font-size: 30px;
    }

    .userProfileContainer {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text {
        font-size: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        color: rgb(0, 0, 0);
    }

    .text strong {
        font-size: 20px;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .commsci-image {
        width: 80%;
    }

    .partner-image {
        width: 80px;
        height: 80px;
    }

    .Modelcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        font-size: 35px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .paperContainer {
        width: 100%;
        padding: 10px;
        margin: 0 auto;
        text-align: center;
    }

    .buttonfilterStyle {
        padding: 25px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: none;
        color: #7BBD8F;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .button-card {
        font-size: 20px;
        padding: 25px 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #7BBD8F;
        border: none;
        color: #ffffff;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .flex-card {
        padding: 32px;
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        text-align: left;
    }

    .cardContent {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardCover {
        height: 300px;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
    }

    .cardCover .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .paperContent {
        padding: 1px;
        margin: 0 auto;
        text-align: center;
    }

    .gridItem {
        margin-bottom: 4%;
        padding: 20px;
    }

    .cardItem-head {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardItem {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardIitleTCover {
        padding: 20px;
    }

    .cardItemCover {
        height: 100%;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
    }

    .cardImage {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
}

@media (max-width: 200px) {
    .Contenttitle {
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 20px;
    }

    .responsive-image {
        max-width: 80%;
        height: auto;
    }

    .footer {
        text-align: center;
        color: #ffffff;
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        font-family: 'Th Sarabun New', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .MainContentContainer {
        padding: 24px;
        margin: 0;
        min-height: 280px;
        background-color: #f1f1f1;
        border-radius: 15px;
    }

    .DB-Card-container1 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .DB-Card-container2 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .PieChartContainer {
        width: 850px;
        height: 1000px;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .CardContainer {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .cardTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .setcardContent {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selectContainer {
        margin-right: 10px;
        font-size: 30px;
        height: 50px;
    }

    .gridContainer {
        font-size: 25px;
    }

    .cardsectionContent {
        font-size: 25px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
        color: white;
        margin-left: 50px;
    }

    .cardContent {
        margin: auto;
        border-radius: 15px;
        width: 100%;
        height: 100%;
    }

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }

    .dataCard {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
        width: 100%;
        height: 100%;
    }

    .pieChartTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .modalContent {
        font-size: 30px;
    }

    .userProfileContainer {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text {
        font-size: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        color: rgb(0, 0, 0);
    }

    .text strong {
        font-size: 20px;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .commsci-image {
        width: 80%;
    }

    .partner-image {
        width: 80px;
        height: 80px;
    }

    .Modelcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        font-size: 35px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .paperContainer {
        width: 100%;
        padding: 10px;
        margin: 0 auto;
        text-align: center;
    }

    .buttonfilterStyle {
        padding: 25px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: none;
        color: #7BBD8F;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .button-card {
        font-size: 20px;
        padding: 25px 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #7BBD8F;
        border: none;
        color: #ffffff;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .flex-card {
        padding: 32px;
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        text-align: left;
    }

    .cardContent {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardCover {
        height: 300px;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
    }

    .cardCover .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .paperContent {
        padding: 1px;
        margin: 0 auto;
        text-align: center;
    }

    .gridItem {
        margin-bottom: 4%;
        padding: 20px;
    }

    .cardItem-head {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardItem {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardIitleTCover {
        padding: 20px;
    }

    .cardItemCover {
        height: 100%;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
    }

    .cardImage {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
}

.cardsection {
    border-radius: 15px;
    background: #7BBD8F;
}

.linecard {
    border-radius: 15px;
    background-color: #f0f2f9;
}

.backgroundColor {
    background: #f0f2f9;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    font-family: 'Th Sarabun New', sans-serif;
}

.your-typography {
    flex-grow: 1;
    margin-right: 5px;
    font-family: 'Th Sarabun New', sans-serif;
    font-weight: bold;
    letter-spacing: .1rem;
    color: gray;
    font-size: 250%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.login-button {
    background: #7BBD8F;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 20px;
    white-space: nowrap;
}

.image-style {
    width: 200px;
    height: auto;
}

.register-button {
    border: 1px solid #7BBD8F;
    color: gray;
    font-weight: bold;
    font-size: 20px;
    white-space: nowrap;
}

.AppBarContainer {
    background-color: #ffffff;
    color: #7BBD8F;
    height: 10%;
}

.menu-button {
    border: 1px solid #7BBD8F;
    color: gray;
    font-weight: bold;
    font-size: 20px;
    white-space: nowrap;
}

.menu-button-primary {
    background: #7BBD8F;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 20px;
    white-space: nowrap;
}

.menu-button-icon {
    font-size: 2rem;
    color: #7BBD8F;
    padding: 0;
}

.float-button-group {
    right: 80px;
}

.custom-icon-button {
    margin-right: 2px;
    display: flex;
}

@media screen and (max-width: 959px) {
    .custom-icon-button {
        display: none;
    }
}

@media (max-width: 300px) {
    .Contenttitle {
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 30px;
    }

    .responsive-image {
        max-width: 80%;
        height: auto;
    }

    .footer {
        text-align: center;
        color: #ffffff;
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        font-family: 'Th Sarabun New', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .MainContentContainer {
        padding: 24px;
        margin: 0;
        min-height: 280px;
        background-color: #f1f1f1;
        border-radius: 15px;
    }

    .DB-Card-container1 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .DB-Card-container2 {
        width: 100%;
        height: 600px;
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .PieChartContainer {
        width: 850px;
        height: 1000px;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .CardContainer {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
    }

    .cardTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .setcardContent {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selectContainer {
        margin-right: 10px;
        font-size: 30px;
        height: 50px;
    }

    .gridContainer {
        font-size: 25px;
    }

    .cardsectionContent {
        font-size: 25px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
        color: white;
        margin-left: 50px;
    }

    .cardContent {
        margin: auto;
        border-radius: 15px;
        width: 100%;
        height: 100%;
    }

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }

    .dataCard {
        margin: auto;
        border-radius: 15px;
        background-color: #FFFFFF;
        width: 100%;
        height: 100%;
    }

    .pieChartTitle {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .modalContent {
        font-size: 30px;
    }

    .userProfileContainer {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text {
        font-size: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        color: rgb(0, 0, 0);
    }

    .text strong {
        font-size: 20px;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .commsci-image {
        width: 80%;
    }

    .partner-image {
        width: 80px;
        height: 80px;
    }

    .Modelcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        font-size: 35px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .paperContainer {
        width: 100%;
        padding: 10px;
        margin: 0 auto;
        text-align: center;
    }

    .buttonfilterStyle {
        padding: 25px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: none;
        color: #7BBD8F;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .button-card {
        font-size: 20px;
        padding: 25px 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #7BBD8F;
        border: none;
        color: #ffffff;
        font-family: 'Th Sarabun New', sans-serif;
    }

    .flex-card {
        padding: 32px;
        font-size: 25px;
        font-family: 'Th Sarabun New', sans-serif;
        text-align: left;
    }

    .cardContent {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardCover {
        height: 300px;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
    }

    .cardCover .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .paperContent {
        padding: 1px;
        margin: 0 auto;
        text-align: center;
    }

    .gridItem {
        margin-bottom: 4%;
        padding: 20px;
    }

    .cardItem-head {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        padding: 20px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardItem {
        height: 100%;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        font-family: 'Th Sarabun New', sans-serif;
        font-size: 25px;
        text-align: left;
    }

    .cardIitleTCover {
        padding: 20px;
    }

    .cardItemCover {
        height: 100%;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
    }

    .cardImage {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
}
.image {
    width: 80px;
    height: 80px;
}

.custom-font {
    font-family: 'Th Sarabun New', sans-serif;
}

body {
    font-family: 'Th Sarabun New', sans-serif;
}

.largeTag {
    font-size: 25px;
    font-weight: bold;
}


.articleTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    font-size: 80px;
    font-weight: bold;
    color: #ffffff;
}

.header-class {
    background-color: #7BBD8F;
    font-size: 30px;
}

.largeTooltip .ant-tooltip-inner {
    font-size: 30px;
    max-width: 200px;
}

.table-font,
.ant-table-cell {
    font-size: 30px;
}

.cell-class {
    font-size: 25px;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-out;
}

.container-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    font-family: 'Th Sarabun New', sans-serif;
}

.form-container {
    width: 80%;
    margin: 0 auto;
}

.form-item-label {
    font-size: 25px;
}

.submit-button {
    padding: 20px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #7BBD8F;
    border: none;
    color: #ffffff;
}

.checkbox-label {
    font-size: 25px;
}

.form-container {
    width: 80%;
    margin: 0 auto;
}

.form-item-label {
    font-size: 25px;
}

.submit-button {
    padding: 20px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #7BBD8F;
    border: none;
    color: #ffffff;
}

.checkbox-label {
    font-size: 25px;
}

.button {
    font-size: 25px;
}

.form-button {
    width: 100%;
    margin-right: 10px;
    font-size: 18px;
    padding: 20px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #7BBD8F;
    border: none;
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
    font-family: 'Th Sarabun New', sans-serif;
}

.form-button-cancel {
    width: 100%;
    margin-right: 10px;
    font-size: 18px;
    padding: 20px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: red;
    border: none;
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
    font-family: 'Th Sarabun New', sans-serif;
}

.login-form-button:hover {
    background: #6aa87e;
}

.login-form-button:active {
    background: #578e6e;
}

.example {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 15px;
}

.content {
    padding: 50px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 15px;
}

.ant-descriptions {
    background-color: #7BBD8F;
}

.ant-descriptions-item-content {
    background-color: #ffffff;
}

.cardheadImage {
    height: 300px;
    width: 50%;
    object-fit: cover;
}

.cardline {
    font-size: 40px;
    font-weight: bold;
    font-family: 'Th Sarabun New', sans-serif;
    color: #25ad4e;

    align-items: center;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.responsive-image1 {
    max-width: 15%;
    height: auto;
    margin: 0 10px;
    /* Optional: Adds space between images */
}

.responsive-image2 {
    max-width: 40%;
    height: auto;
    margin: 0 10px;
    /* Optional: Adds space between images */
}

.searchContainer {
    display: flex;
    align-items: center;
    /* Optional: Align items vertically */
}

/* .chart-container {
    display: flex;
} */
.chart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.chart .chart_bar {
    display: inline-block;
    width: 200px;
    /* Adjust width as necessary */
    height: 20px;
    /* Adjust height as necessary */
    background-color: #8884d8;
    /* Default color */
    margin-bottom: 5px;
}

.chart .label {
    display: inline-block;
    margin-left: 10px;
}

.right-aligned-header .MuiDataGrid-columnHeaderWrapper {
    justify-content: flex-end;
}

.rounded-input {
    border-radius: 25px; /* Adjust this value to control the roundness */
  }