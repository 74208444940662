@import url('https://fonts.cdnfonts.com/css/th-sarabun-new-4');

.custom-font {
  font-family: 'Th Sarabun New', sans-serif;
}

body {
  font-family: 'Th Sarabun New', sans-serif;
}

.register-button {
  border: 1px solid #7BBD8F;
  color: gray;
  font-weight: bold;
  font-size: 20px;
  white-space: nowrap;
}

@media (max-width: 2000px) {
  .AppBarContainer {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1800px) {
  .AppBarContainer {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1200px) {
  .AppBarContainer {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 800px) {
  .AppBarContainer {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 500px) {
  .AppBarContainer {
    display: flex;
    justify-content: center;
  }
}

.form-button {
  width: 100%;
  margin-right: 10px;
  font-size: 18px;
  padding: 20px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7BBD8F;
  border: none;
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
  font-family: 'Th Sarabun New', sans-serif;
}